import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicy = (data) => {
     const pageQuery = useStaticQuery(graphql`
    query privacyQuery {
        allWpPage(filter: {uri: {eq: "/privacy-policy/"}}) {
            nodes {
                uri
                title
                content
                seo {
                    metaDesc
                    title
                }
            }
        }
    }
    `)
    const page = pageQuery.allWpPage.nodes[0]
    
    return (
        <div>
            <Seo title={pageQuery.seo?.title ? pageQuery.seo.title  :  page.title } description={pageQuery.seo?.metaDesc} />

            <Layout>
                <section className="header_section">
                <div className="container">
                    <div className="header_section__content">
                        <h1>{page.title}</h1>
                        <div className="header_section__content_sub">
                            <div>The Expert Recruiters in Medical Aesthetics </div>
                        </div>
                        <div className="header_section__content_btn text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="bounce" width="109.491" height="57.574" viewBox="0 0 109.491 57.574">
                                <path id="Path_5" data-name="Path 5" d="M-847.3,2972.583h0l53.331,53.331-53.331,53.332" transform="translate(3080.66 848.711) rotate(90)" fill="none" stroke="rgba(53,51,67,0.5)" strokeWidth="4"/>
                            </svg>
                        </div> 
                    </div>
                    {/* <div className="header_section__image">
                        <div className="header-container--standars">
                                <img src="/assets/images/header/image_3.svg" alt="Image 3" />
                        </div> 
                    </div> */}
                </div>
                <div className="standard_header_path">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1924.003 706.339">
                        <path id="Path_1" data-name="Path 1" d="M.5,1210.979h1924V504.64S1271.73,915.921,929.1,801.894.5,1037.8.5,1037.8Z" transform="translate(-0.5 -504.64)" fill="#ecebe4"/>
                    </svg>                      
                </div>
            </section>

            <section className="intro_text blog_intro">
                <div className="container">
                    <div className="section__title text-center">{page.title}</div>

                    <div className="intro_text_holder fullWidth" style={{maxWidth: '100%'}}>
                        <div 
                        dangerouslySetInnerHTML={{
                            __html: page.content
                        }}
                        />
                    </div>
                </div>
            </section>

        
            </Layout>
        </div>
    )
}

export default PrivacyPolicy

